* {
  padding: 0;
  margin: 0;
  font-family: Poppins, sans-serif, Arial;
  box-sizing: border-box;
}

html,
body,
#__next {
  width: 100%;
  height: 100%;
}

#__next {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}
